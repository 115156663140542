import React from 'react';
import cn from 'classnames';
import PageLayout from 'components/common/PageLayout';
import css from './PrivacyPolicy.module.scss';

const PrivacyPolicy: React.FC<{}> = () => {
  return (
    <PageLayout contentClassName={css.root}>
      <h1 className={cn(css.t1, 'text-center', 'font-semibold')}>Privacy Policy</h1>
      <section>
        <p>Last updated: 01 Jan 2025</p>
        <p>
          This Privacy Policy describes Our (hereinafter <strong>"Storycards"</strong>, <strong>"Our"</strong>,{' '}
          <strong>"We"</strong>,<strong> "Company"</strong>) policies and procedures on the collection, use and
          disclosure of Your information when You use our Services, software, website (including any extension)
          (collectively <strong>"The Platform"</strong>) and tells You about Your privacy rights.
        </p>
        <p>
          Storycards take your privacy seriously and we make effort to provide safe and secure services, and with
          respect to your privacy. We use Your Collected data, as defined below, to provide and improve the Service. By
          using the Service, You agree to the collection, process, and use of the Personal Data and Collected Data under
          this Privacy Policy.
        </p>
        <p>
          This privacy policy and the Data Protection Policy (<strong>"DPP"</strong>), are a part of Our Terms of Use.
          Make sure to read them carefully as they outline your rights and obligations, and other legal information.
        </p>
        <p>
          If you have any questions about this Privacy Policy, You can contact us:{' '}
          <a href="mailto:privacy@storycards.com">privacy@storycards.com</a>
        </p>

        <br />

        <ol>
          <li>
            <h2 className={css.t2}>1. Interpretation and Definitions</h2>
            <p>
              The following definitions shall have the same meaning regardless of whether they appear in singular or
              plural.
            </p>

            <ol>
              <li>
                <h3 className={css.t3}>For the purposes of this Privacy Policy:</h3>
                <ul>
                  <li>
                    <strong>You, Your, User</strong>&nbsp;–&nbsp;means the individual accessing or using the Service and
                    the Platform, or the company, or other legal entity on behalf of which such individual is accessing
                    or using the Service and the Platform, as applicable. <strong>"Subscriber(s)"</strong> shall mean
                    the entity or individual that has made the initial registration process and payments. Unless
                    otherwise stated, wherever this Agreement refers to a User(s), it also applies to Subscribers.
                  </li>
                  <li>
                    <strong>Visitor</strong>&nbsp;–&nbsp;means Your users, clients, or other visitors interacting with
                    Your Products.
                  </li>
                  <li>
                    <strong>Cookies</strong>&nbsp;–&nbsp;are small files that are placed on Your computer, mobile
                    device, or any other device by a website, containing the details of Your browsing history on that
                    website among its many uses. Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
                    remain on Your personal computer or mobile device when You go offline, while Session Cookies are
                    deleted as soon as You close Your web browser.&nbsp;
                  </li>
                  <li>
                    <strong>Personal Data</strong> or <strong>Personal Information</strong>
                    &nbsp;–&nbsp;is any information that relates to an identified or identifiable individual.
                  </li>
                  <li>
                    <strong>Service</strong>&nbsp;–&nbsp;refers to the Platform or the Website or any value given by the
                    company to the user.
                  </li>
                  <li>
                    <strong>Products</strong>&nbsp;–&nbsp;shall mean any product and/or result and/or work created on or
                    with Our Platform and Services.
                  </li>
                  <li>
                    <strong>Service Provider</strong>&nbsp;–&nbsp;means any natural or legal person who processes the
                    data on behalf of the Company. It refers to third-party companies or individuals employed by the
                    Company to facilitate the Service, to provide the Service on behalf of the Company, to perform
                    services related to the Service or to assist the Company in analyzing how the Service is used.
                  </li>
                  <li>
                    <strong>Usage Data</strong>&nbsp;–&nbsp;refers to data collected automatically, either generated by
                    the use of the Service, or from the Service infrastructure itself (for example, the duration of a
                    page visit).
                  </li>
                  <li>
                    <strong>Website</strong>&nbsp;–&nbsp; refers to Storycards - accessible from&nbsp;
                    <a href="https://www.storycards.com">https://www.storycards.com</a>
                  </li>
                </ul>
              </li>
            </ol>
          </li>

          <li>
            <h2 className={css.t2}>2. Collecting and Using Your Collected data</h2>

            <ol>
              <li>
                <h3 className={css.t3}>Collected data</h3> To provide you the Services, we must collect Personal Data
                relating to You and Visitors (collectively "<strong>Collected data"</strong>). We collect Personal Data
                you provide us, from Yours, and Your Visitors use of the Services, and from other sources. Here are the
                types of Personal Data we collect about you:&nbsp;
                <ul>
                  <li>
                    <strong>Information You provide us:</strong> When you register for our Services, make engagement
                    with Our Products, use any of Our Services; and/or when you contact us directly by any communication
                    channel, you may provide us Personal Information, such as name, email address, phone number, payment
                    information, the information you include in your communications with us, and Personal Information
                    contained in scanned identification documents (such as an ID card, driver&rsquo;s license, passport,
                    or official company registration documents).
                  </li>
                  <li>
                    <strong>Usage Data:</strong> Usage data is collected automatically when using the Service. When you
                    visit, download, and/or use any of our Services, we may collect aggregated usage Personal Data, such
                    as Visitor`s and User`s browsing and &lsquo;click-stream&rsquo; activity on the Services, session
                    heatmaps and scrolls, non-identifying Personal Information regarding the Visitors&rsquo; or
                    Users&rsquo; device, operating system, internet browser, screen resolution, language and keyboard
                    settings, internet service provider, referring/exit pages, date/time stamps, etc.
                  </li>
                  <li>
                    <strong>Information we collect from other sources:</strong> We may receive Personal Information
                    about you from third-party sources, such as i) security providers, fraud detection and prevention
                    providers, for example, to help us screen out users associated with fraud, ii) social media
                    platforms, when you log-in or sign-up using your social media account, we may receive Personal Data
                    from that service (e.g., your username, basic profile Personal Information) and in some cases, we
                    may collect Personal Data from lead enhancement companies which help us to improve our service
                    offering.
                  </li>
                  <li>
                    <strong>Information we collect from Visitors:</strong> in order to provide with Our Services (for
                    example, engagement analytics for your products), we collect information received from Visitor`s
                    engagement with the Products ( for example: answers for yes/no poll).
                  </li>
                </ul>
              </li>

              <li>
                <h3 className={css.t3}>Tracking Technologies and Cookies</h3>
                <p>
                  We use Cookies and similar tracking technologies to track the activity on Our Service and store
                  certain information. Tracking technologies used are beacons, tags, and scripts to collect and track
                  information and to improve and analyze Our Service. The technologies We use may include:
                </p>

                <ul>
                  <li>
                    <strong>Cookies or Browser Cookies.</strong>&nbsp;A cookie is a small file placed on Your Device.
                    You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent.
                    However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless
                    you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.
                  </li>
                  <li>
                    <strong>Flash Cookies.</strong>&nbsp;Certain features of our Service may use local stored objects
                    (or Flash Cookies) to collect and store information about Your preferences or Your activity on our
                    Service. Flash Cookies are not managed by the same browser settings as those used for Browser
                    Cookies.
                  </li>
                  <li>
                    <strong>Web Beacons.</strong>&nbsp;As between Storycards and You, we do not collect any Personal
                    Data from you with web Beacons, though certain sections of Our Service and Our emails may (within
                    your choice) contain small electronic files known as web beacons (also referred to as clear gifs,
                    pixel tags, and single-pixel gifs) that permit the Storycards, for example, to count Visitors who
                    have visited those pages or opened an email and for other related Products statistics (for example,
                    recording the popularity of a certain section and verifying system and server integrity).
                  </li>
                </ul>

                <br />
                <p>We may use both Session and Persistent Cookies for the purposes set out below:</p>

                <ul>
                  <li>
                    <strong>Necessary / Essential Cookies</strong>
                    <p>Type: Session Cookies</p>
                    <p>Administered by: Us</p>
                    <p>
                      Purpose: These Cookies are essential to provide You with services available through the Website
                      and to enable You to use some of its features. They help to authenticate users and prevent
                      fraudulent use of user accounts. Without these Cookies, the services that You have asked for
                      cannot be provided, and We only use these Cookies to provide You with those services.
                    </p>
                  </li>
                  <li>
                    <strong>Cookies Policy / Notice Acceptance Cookies</strong>
                    <p>Type: Persistent Cookies</p>
                    <p>Administered by: Us</p>
                    <p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
                  </li>
                  <li>
                    <strong>Functionality Cookies</strong>
                    <p>Type: Persistent Cookies</p>
                    <p>Administered by: Us</p>
                    <p>
                      Purpose: These Cookies allow us to remember choices You make when You use the Website, such as
                      remembering your login details or language preference. The purpose of these Cookies is to provide
                      You with a more personal experience and to avoid You having to re-enter your preferences every
                      time You use the Website.
                    </p>
                  </li>
                </ul>
              </li>

              <li>
                <h3 className={css.t3}>Collected Data of Your Visitors and users</h3>
                <p>
                  As mentioned above, in order to provide you Our services we collect the data mentioned above also for
                  your Visitors and users. With this collected Data we Provide you with analytics and other necessary
                  information. We also use this Data to improve Our Products to be more attractive, enjoyable and
                  engaging.
                </p>
                <p>
                  Please Note: that as a User of Our Platform and Services you subjected to Our DPP that outlines Our
                  agreement with You on the processing of Personal Data.
                </p>
                <p>
                  Data collected from Visitors will be used solely on Your behalf, and You acknowledge and agree to
                  serve as Data Controllers (or as Businesses, as defined under the CCPA, where the User reside in
                  California). We may process the data collected on Our own behalf only on and aggregated and anonymized
                  basis for analytics and improvement of our Platform and Services.
                </p>
                <p>
                  If we combine Personal Information with anonymous information about you, the combined information will
                  be treated as Personal Information for as long as it remains combined.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <h2 className={css.t2}>3. Use of Your Collected Data</h2>

            <ol>
              <li>
                <h3 className={css.t3}>The Company may use Collected Data for the following purposes:</h3>

                <ul>
                  <li>
                    <strong>To provide and maintain our Service</strong>: including monitoring the usage of our Service.
                  </li>
                  <li>
                    <strong>To Develop and improve the services:</strong> To further develop, customize, expand, and
                    improve of our Services, based on Users&rsquo; common or personal preferences, experiences, and
                    difficulties.
                  </li>
                  <li>
                    <strong>To manage Your User account:</strong>&nbsp;to manage Your registration, and subscription as
                    a user of the Service, including payments and password renewal.&nbsp;
                  </li>
                  <li>
                    <strong>To analyze preferences and activities</strong>. In order to provide you with high-quality
                    engagement products we use all collected data and processes it for Our users and to improve Our
                    Platform and Services.
                  </li>
                  <li>
                    <strong>For the performance of a contract:</strong>&nbsp;the development, compliance and undertaking
                    of the purchase contract for the services you have purchased or of any other contract with Us
                    through the Service.
                  </li>
                  <li>
                    <strong>To contact You:</strong>&nbsp;To contact You by email, or other equivalent forms of
                    electronic communication, regarding updates or informative communications related to the
                    functionalities, help and support if needed, products or contracted services, including the security
                    updates, when necessary or reasonable for their implementation.
                  </li>
                  <li>
                    <strong>To provide You&nbsp;with news, special offers and general information </strong>
                    about other goods, services and events which we offer that are similar to those that you have
                    already purchased or enquired about unless You have opted not to receive such information.
                  </li>
                  <li>
                    <strong>To manage Your requests:</strong>&nbsp;To attend and manage Your requests to Us.
                  </li>
                  <li>
                    <strong>For business transfers:</strong>&nbsp;We may use the Collected Data to evaluate or conduct a
                    merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some
                    or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar
                    proceeding, in which Collected Data held by Us about our Service users is among the assets
                    transferred.
                  </li>
                  <li>
                    <strong>Protecting our interests</strong> &ndash; we use and disclose your Personal Data when we
                    believe it&rsquo;s necessary in order to comply with a legal obligation, take precautions against
                    liabilities, investigations and to defend ourselves against any third-party claims or allegations,
                    prevent and protect ourselves from fraud and wrongdoing in connection with the Services, protect the
                    security or integrity of our Services and protect the rights and property of Storycards, its Users,
                    Visitors and/or partners.
                  </li>
                  <li>
                    <strong>For other purposes</strong>: We may use Collected Data for other purposes, such as data
                    analysis, identifying usage trends, determining the effectiveness of our promotional campaigns, and
                    evaluating and improving our Service, products, services, marketing and Your experience.
                  </li>
                </ul>
              </li>

              <li>
                <h3 className={css.t3}>We may share the Collected Data in the following situations:</h3>

                <ul>
                  <li>
                    <strong>With Service Providers:</strong>&nbsp;We may share the Collected Data with Our third-party
                    Service Providers. Such information may include: identifiers, including name, alias, postal address,
                    unique personal identifier, online identifier, internet protocol address, email address, account
                    name, or other similar identifiers, and usage statistics and commercial personal information.
                  </li>
                  <li>
                    <strong>For business transfers:</strong>&nbsp;We may share or transfer the Collected Data in
                    connection with, or during negotiations of, any merger, sale of Our Company`s assets, financing, or
                    acquisition of all or a portion of Our business to another entity.
                  </li>
                  <li>
                    <strong>Social Media Features</strong>: Our Services may enable you to integrate certain Social
                    Media features, widgets, and single sign-on features, such as &ldquo;Google Sign-in&rdquo; (&ldquo;
                    <strong>Social Media Features</strong>
                    &rdquo;) into your User Products. These Social Media Features may collect certain Personal
                    Information from your visitors and users such as identifiers, including name, alias, unique personal
                    identifier, online identifier, internet protocol address, email address, or other similar
                    identifiers. Social Media Features are hosted either by a third-party or directly on our Services.
                    Please note that We allow you to procure the services of such third-party Services (including, but
                    not limited to, third-party developers, and/or Apps and Social Media Features) with which you are
                    interacting directly, and at your discretion. In this respect, We act as a service provider to you,
                    disclosing information to the third-party services on your behalf. We do not control and are not
                    responsible for the actions or policies of any third-party service, and your use of any third-party
                    service is at your own risk.&nbsp;
                  </li>
                  <li>
                    <strong>With Affiliates:</strong>&nbsp;We may share the Collected Data with Our affiliates, in which
                    case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent
                    company and any other subsidiaries, joint venture partners or other companies that We control or
                    that are under common control with Us.
                  </li>
                  <li>
                    <strong>Professional advisers and internal parties:</strong> we may share Collected Data with our
                    workers as well as our professional advisers acting as processors or controllers, to provide you
                    with our services.
                  </li>
                  <li>
                    <strong>With business partners:</strong>&nbsp;We may share the Collected Data with Our business
                    partners to offer You certain products, services or promotions.
                  </li>
                  <li>
                    <strong>With Your consent</strong>: We may disclose Your personal information for any other purpose
                    with Your consent.
                  </li>
                  <li>
                    <strong>Law enforcement.</strong> Under certain circumstances, the Company may be required to
                    disclose Collected data or part of it, if required to do so by law or in response to valid requests
                    by public authorities (e.g. a court or a government agency).
                  </li>
                </ul>
              </li>

              <li>
                <h3 className={css.t3}>Other legal requirements</h3>
                The Company may disclose the Collected Data or part of it, in the good faith belief that such action is
                necessary to
                <ul>
                  <li>Comply with a legal obligation</li>
                  <li>Protect and defend the rights or property of the Company</li>
                  <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
                  <li>Protect the personal safety of Users of the Service or the public</li>
                  <li>Protect against legal liability</li>
                </ul>
              </li>
            </ol>
          </li>

          <li>
            <h2 className={css.t2}>4. Retention of Your Collected Data</h2>

            <ol>
              <li>
                The Company will retain Your Collected Data only for as long as is necessary for the purposes set out in
                this Privacy Policy. We will retain and use Your Collected Data to the extent necessary to comply with
                our legal obligations (for example, if we are required to retain your data to comply with applicable
                laws), resolve disputes, and enforce our legal agreements and policies.
              </li>
              <li>
                The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained
                for a shorter period of time, except when this data is used to strengthen the security or to improve the
                functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
              </li>
            </ol>
          </li>

          <li>
            <h2 className={css.t2}>5. Transfer of Your Collected data</h2>

            <ol>
              <li>
                Your information, including Collected data, is processed at the Company's operating offices and in any
                other places where the parties involved in the processing are located. It means that this information
                may be transferred to &mdash; and maintained on &mdash; computers located outside of Your state,
                province, country or other governmental jurisdiction where the data protection laws may differ from
                those from Your jurisdiction.
              </li>
              <li>
                Your consent to this Privacy Policy followed by Your submission of such information represents Your
                agreement to that transfer.
              </li>
              <li>
                The Company will take all steps reasonably necessary to ensure that all Collected Data is treated
                securely and in accordance with this Privacy Policy and no transfer of Your Collected data will take
                place to an organization or a country unless there are adequate controls in place including the security
                of Your data and other personal information.
              </li>
            </ol>
          </li>

          <li>
            <h2 className={css.t2}>6. Security of Your Collected data</h2>

            <p>
              The security of Your Collected data is important to us, but remember that no method of transmission over
              the Internet, or method of electronic storage is 100% secure. While We strive to use commercially
              acceptable means to protect Your Collected data, We cannot guarantee its absolute security.
            </p>
          </li>

          <li>
            <h2 className={css.t2}>7. Your rights as a User and/or Visitor</h2>

            <ol>
              <li>
                You can contact us with regard to your Personal Data. Before giving you any details, we may ask for any
                information or documents, for proving your identity.
              </li>
              <li>
                You may contact us: (1) to get a copy of your Personal Data; (2) to ask to delete or block use in your
                Personal Data(3) to receive confirmation as to whether or not Your Personal Data is being processed (4)
                to lodge a complaint with a supervisory authority.&nbsp;
              </li>
              <li>
                You may contact us at our email address specified at the beginning of this Privacy Policy. When
                contacting us, please indicate in the title the nature of your request. Keep in mind that you may be
                asked to identify yourself and send additional information about yourself.
              </li>
              <li>
                Please note: that if ask us not to use your Personal Data it will not be used, unless for our legitimate
                interests, like safety and security of the platform or compliance with the law.
              </li>
              <li>
                By using our services and platform you giving your consent for us to use your information and Personal
                Data in the terms set forth in this Privacy Policy. You may at any time withdraw from your consent by
                notice as set forth above.
              </li>
            </ol>
          </li>

          <li>
            <h2 className={css.t2}>8. Children's Privacy</h2>

            <p>
              Our Service does not address anyone under the age of 18. We do not knowingly collect personally
              identifiable information from anyone under the age of 18. If You are a parent or guardian and You are
              aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We
              have collected Personal Data from anyone under the age of 18 without verification of parental consent, We
              take steps to remove that information from Our servers.
            </p>
          </li>

          <li>
            <h2 className={css.t2}>9. Links to Other Websites</h2>

            <p>
              Our Service may contain links to other websites that are not operated by Us. If You click on a third-party
              link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy
              of every site You visit.
            </p>
            <p>
              We have no control over and assume no responsibility for the content, privacy policies or practices of any
              third-party sites or services.
            </p>
          </li>

          <li>
            <h2 className={css.t2}>10. Changes to this Privacy Policy</h2>

            <p>
              We may update Our Privacy Policy from time to time. We will notify on our Website of any changes or new
              Privacy Policy.
            </p>
            <p>
              You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy
              are effective when they are posted on Our Website.
            </p>
            <p>
              By continuing to use Our Platform and Services you agree and accept all terms and conditions stated in
              this Privacy Policy above.
            </p>
          </li>
        </ol>
      </section>
    </PageLayout>
  );
};

export default PrivacyPolicy;
